import api from "@services/axiosInstance";

function getBellMessages(){
  return api.get(`/info-msg`);
}

function setBellMessages(){
    return api.post(`/settings/landing-links`);
  }

export default {
  getBellMessages,
  setBellMessages
}