const initialState = () => ({
  backendUrl: "",
  downloadUrl: "",
  is_download_innovation_season: false,
  rollbar_token: "",
  adminUrl: "",
  eventUrl: "",
  landingUrl: "",
  platformUrl: "",
  projectsUrl: "",
  authUrl: "",
  pnrr_brochure_url: "",
  platformEnv: "",
  cookieDomain: "",
});

const state = initialState();

const mutations = {
  resetState(state) {
    Object.assign(state, initialState());
  },
  setData(state, data) {
    state.backendUrl = data.VUE_APP_BE;
    state.downloadUrl = data.DW_LOGIN_URL;
    state.is_download_innovation_season = data.IS_DOWNLOAD_INNOVATION_SEASON;
    state.rollbar_token = data.VUE_APP_ROLLBAR_TOKEN;
    state.adminUrl = data.ADMIN_URL;
    state.eventUrl = data.EVENT_URL;
    state.landingUrl = data.LANDING_URL;
    state.platformUrl = data.PLATFORM_URL;
    state.projectsUrl = data.PROJECTS_URL;
    state.authUrl = data.AUTH_URL;
    state.pnrr_brochure_url = data.PNRR_BROCHURE_URL;
    state.platformEnv = data.PLATFORM_ENV;
    state.cookieDomain = data.COOKIE_DOMAIN;
  },
};

const actions = {
  async loadData({ commit }) {
    try {
      const fullUrl = window.location;
      const redirectUrl = `${fullUrl.protocol}//${fullUrl.host}/config.json`;
      const resp = await fetch(redirectUrl);
      const data = await resp.json();
      commit("setData", data);
    } catch (e) {
      console.log(e);
    }
  },
};

const getters = {
  backendUrl: (state) => state.backendUrl,
  downloadUrl: (state) => state.downloadUrl,
  isDownloadSeason: (state) => state.is_download_innovation_season,
  rollbarToken: (state) => state.rollbar_token,
  adminUrl: (state) => state.adminUrl,
  eventUrl: (state) => state.eventUrl,
  landingUrl: (state) => state.landingUrl,
  platformUrl: (state) => state.platformUrl,
  projectsUrl: (state) => state.projectsUrl,
  authUrl: (state) => state.authUrl,
  pnrr_brochure_url: (state) => state.pnrr_brochure_url,
  platformEnv: (state) => state.platformEnv,
  cookieDomain: (state) => state.cookieDomain,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
