<template>
  <div class="event-sponsor my-7">
    <v-row>
      <v-col
        cols="12"
        align="center"
      >
        <v-img
          contain
          src="@/assets/event/hologram.webp"
          max-width="130"
          alt="hologram"
        />
      </v-col>
      <v-col cols="12">
        <h2
          class="mb-5 event-sponsor_subtitle text-center"
        >
          scuole iscritte
        </h2>     
      </v-col>
    </v-row>
    <v-row class="mb-6 ml-2">
      <SchoolsCarousel
        :columns="this.$vuetify.breakpoint.mdAndUp ? 6 : 3"
        :schools="eventSchools"
      />
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col>
            <h2
              class="mb-5 event-sponsor_text text-center"
            >
              Con la collaborazione di:
            </h2>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="space-around"
          class="mx-1 my-0 py-2"
          max-width="50"
        >
          <v-col
            sm="5"
          >
            <v-img
              contain
              max-height="90"
              src="@/assets/m2a.webp"
              alt="logo_m2a"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col>
            <h2
              class="mb-5 event-sponsor_text text-center"
            >
              Sponsorizzato da:
            </h2>
          </v-col>
        </v-row>
        <v-row
          align="center"
          justify="space-around"
          class="mx-1 my-0 py-2"
          max-width="50"
        >
          <v-col
            sm="6"
          >
            <v-img
              contain
              max-height="90"
              src="@/assets/generali.webp"
              alt="generali"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { eventSchools } from "@data/eventSchools.js"
import matomoTracker from "@mixins/matomoTracker";
import SchoolsCarousel from './SchoolsCarousel.vue';


export default {
  components: { SchoolsCarousel },
  name: "EventSponsor",
  mixins:[matomoTracker],
  data() {
    return {
      eventSchools
    };
  },
};
</script>

<style scoped>
.event-sponsor {
  max-width: 1100px;
  margin: auto;
}
.event-sponsor_subtitle {
  font-family: 'outerorbit';
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 37px;
  text-align: center;
  color: #FFFFFF;
}
/* .event-sponsor_subtitle:before {
  content: '';
  background: url("~@/assets/event/hologram.webp") no-repeat;
  background-size: contain;
  width: 100px;
  height: 100px;
  display: inline-flex;
  align-items: center;
} */
.event-sponsor_schools {
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #98A6AE;
}
@media only screen and (max-width: 600px) {
  .event-sponsor_schools {
    font-size: 15px;
  line-height: 0px;
}
}
.event-sponsor_text {
  font-family: 'Karasuma Gothic';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
}
</style>
