import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import { setupAxiosInstance } from "@/services/axiosInstance";
import VueMatomo from "vue-matomo";
import Rollbar from "rollbar";
import VueDOMPurifyHTML from "vue-dompurify-html";

Vue.config.productionTip = false;

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

Vue.use(VueDOMPurifyHTML, {
  default: {
    USE_PROFILES: { html: false },
  },
});

Vue.use(VueMatomo, {
  host: "https://stats.hackersgen.com/",
  siteId: process.env.NODE_ENV === "production" ? 1 : 2,
  trackerFileName: "matomo",
  router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  // userId: undefined,
  // cookieDomain: undefined,
  // domains: undefined,
  preInitActions: [],
});

store.dispatch("config/loadData").then(() => {
  setupAxiosInstance();

  Vue.prototype.$rollbar = new Rollbar({
    accessToken: store.getters["config/rollbarToken"],
    captureUncaught: true,
    captureUnhandledRejections: true,
  });

  Vue.prototype.$logger = function (msg, additionalParam = null) {
    if (process.env.NODE_ENV !== "production") {
      // eslint-disable-next-line no-unused-expressions
      additionalParam ? console.log(msg, additionalParam) : console.log(msg);
    }
  };

  Vue.config.errorHandler = (err, vm) => {
    vm.$rollbar.error(err);
    throw err; // rethrow
  };

  const _paq = (window._paq = window._paq || []);
  _paq.push(["trackVisibleContentImpressions"]);

  new Vue({
    name: "HackersGen",
    vuetify,
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
