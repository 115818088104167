<template>
  <v-row no-gutters justify="start">
    <v-row no-gutters align="center" class="goback-wrapper" @click="goBack">
      <img
        class="left-arrow"
        src="@assets/landingPage/left-arrow.svg"
        alt="left arrow"
      />
      <h4 class="goback-text ml-5">{{ text }}</h4>
    </v-row>
  </v-row>
</template>

<script>
export default {
  name: "GoBackButton",
  props: {
    text: {
      type: String,
      default: "TORNA ALLA HOME",
    },
    route: {
      type: [String, Object],
      default: "/",
    },
  },
  methods: {
    goBack() {
      this.$router.push(this.route);
    },
  },
};
</script>

<style scoped>
.left-arrow {
  width: 12px;
  height: 12px;
  object-fit: contain;
  object-position: center;
}
.goback-text {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #f4f5f6;
}
.goback-wrapper {
  width: fit-content;
  cursor: pointer;
  margin: 10px 0;
}
</style>
