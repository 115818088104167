import api from "@services/axiosInstance";

/**
 * API DOC: https://s4s-main.sorintdev.it/api/docs/swagger/index.html#/User/post_contact_us
 */
function contactUs(from, text) {
  return api.post("/contact-us", {
    from,
    text,
  });
}

export default {
  contactUs,
};
