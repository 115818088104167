<template>
  <v-system-bar
    v-if="messages.length > 0"
    app
    class="px-0 mx-0"
    :height="36"
    :color="'#F39200'"
  >
    <v-container class="bell-wrapper">
      <div ref="containerWrapper" class="container-wrapper">
        <v-row
          v-if="containerWrapperWidth"
          id="marquee-row"
          no-gutters
          align="center"
          :justify="messages.length > 1 ? 'space-between' : 'start'"
        >
          <img
            src="@assets/landingPage/bell_icon.svg"
            class="bell-icon mr-2"
            :class="{ 'bell-animation': showAnimation }"
            alt="icon bell"
          />
          <div
            :style="{
              width: `${
                containerWrapperWidth -
                ($vuetify.breakpoint.mdAndUp ? 252 : 36) -
                23 -
                10
              }px`,
              position: 'relative',
            }"
          >
            <transition-group
              name="slide-fade"
              tag="div"
              class="transition-container"
            >
              <MarqueeText
                v-for="message in selectedMessage"
                :key="message.text + $vuetify.breakpoint.name"
                :duration="15"
                :repeat="$vuetify.breakpoint.mdAndUp ? 1 : 10"
                :paused="$vuetify.breakpoint.mdAndUp"
                class="message-box marquee-container"
              >
                <a
                  :key="message.text"
                  class="custom-marquee mr-10"
                  :style="{ pointerEvents: message.link ? 'auto' : 'none' }"
                  :href="message.link"
                  @click="()=>trackEvent('LandingPage', 'ClickOnLandingBellText', message.link)"
                  target="_blank"
                >
                  {{ message.text }}
                </a>
              </MarqueeText>
            </transition-group>
          </div>
          <v-btn
            class="marquee-btn"
            v-if="messages.length > 1"
            :text="$vuetify.breakpoint.mdAndUp"
            :icon="!$vuetify.breakpoint.mdAndUp"
            @click="updateBellContent"
          >
            {{ $vuetify.breakpoint.mdAndUp ? "Annuncio successivo" : "" }}
            <v-icon :right="$vuetify.breakpoint.mdAndUp">
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-row>
      </div>
    </v-container>
  </v-system-bar>
</template>

<script>
import services from "@services/bellMessages";
import { handleAxiosError } from "@utils/notifications";
import MarqueeText from "vue-marquee-text-component";
import matomoTracker from "@mixins/matomoTracker";

export default {
  name: "LandingBell",
  components: { MarqueeText },
  mixins:[matomoTracker],
  data() {
    return {
      showAnimation: true,
      index: 0,
      messages: [],
      containerWrapperWidth: 0,
    };
  },
  computed: {
    selectedMessage() {
      return this.messages.filter((c, k) => k === this.index);
    },
  },
  async created() {
    try {
      const { data } = await services.getBellMessages();
      this.messages = data;
      // remove bell Animation after 10seconds
      setTimeout(() => {
        this.showAnimation = false;
      }, 12000);
      setTimeout(this.updateBellWidth, 100);
      return data;
    } catch (err) {
      await handleAxiosError("errore nel caricamento dei messaggi", err);
      return null;
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.updateBellWidth);
  },
  mounted() {
    window.addEventListener("resize", this.updateBellWidth);
  },
  methods: {
    updateBellContent() {
      this.showAnimation = false;
      if (this.index + 1 < this.messages.length) {
        this.index++;
      } else {
        this.index = 0;
      }
      this.showAnimation = true;
    },
    updateBellWidth() {
      const containerWidth = this.$refs.containerWrapper?.clientWidth;
      this.containerWrapperWidth = containerWidth ?? 600;
    },
  },
};
</script>

<style scoped>
.custom-marquee {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #212b31;
}

.bell-wrapper{
  max-width: 1200px;
}
.container-wrapper {
  width: 100%;
}
.marquee-container {
  width: 100%;
}

#marquee-row {
  background-color: #f39200;
  font-weight: 700;
}

.marquee-btn {
  font-family: "Karasuma Gothic";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #212b31;
  text-transform: capitalize;
  z-index: 10;
}

a {
  text-decoration: none;
}

.bell-icon {
  object-fit: contain;
  object-position: center;
  height: 24px;
  width: 21.75px;
}

.bell-animation {
  -webkit-animation: ring 3s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 3s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 3s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotateZ(0);
  }
  1% {
    -webkit-transform: rotateZ(30deg);
  }
  3% {
    -webkit-transform: rotateZ(-28deg);
  }
  5% {
    -webkit-transform: rotateZ(34deg);
  }
  7% {
    -webkit-transform: rotateZ(-32deg);
  }
  9% {
    -webkit-transform: rotateZ(30deg);
  }
  11% {
    -webkit-transform: rotateZ(-28deg);
  }
  13% {
    -webkit-transform: rotateZ(26deg);
  }
  15% {
    -webkit-transform: rotateZ(-24deg);
  }
  17% {
    -webkit-transform: rotateZ(22deg);
  }
  19% {
    -webkit-transform: rotateZ(-20deg);
  }
  21% {
    -webkit-transform: rotateZ(18deg);
  }
  23% {
    -webkit-transform: rotateZ(-16deg);
  }
  25% {
    -webkit-transform: rotateZ(14deg);
  }
  27% {
    -webkit-transform: rotateZ(-12deg);
  }
  29% {
    -webkit-transform: rotateZ(10deg);
  }
  31% {
    -webkit-transform: rotateZ(-8deg);
  }
  33% {
    -webkit-transform: rotateZ(6deg);
  }
  35% {
    -webkit-transform: rotateZ(-4deg);
  }
  37% {
    -webkit-transform: rotateZ(2deg);
  }
  39% {
    -webkit-transform: rotateZ(-1deg);
  }
  41% {
    -webkit-transform: rotateZ(1deg);
  }
  43% {
    -webkit-transform: rotateZ(0);
  }
  100% {
    -webkit-transform: rotateZ(0);
  }
}

@-moz-keyframes ring {
  0% {
    -moz-transform: rotate(0);
  }
  1% {
    -moz-transform: rotate(30deg);
  }
  3% {
    -moz-transform: rotate(-28deg);
  }
  5% {
    -moz-transform: rotate(34deg);
  }
  7% {
    -moz-transform: rotate(-32deg);
  }
  9% {
    -moz-transform: rotate(30deg);
  }
  11% {
    -moz-transform: rotate(-28deg);
  }
  13% {
    -moz-transform: rotate(26deg);
  }
  15% {
    -moz-transform: rotate(-24deg);
  }
  17% {
    -moz-transform: rotate(22deg);
  }
  19% {
    -moz-transform: rotate(-20deg);
  }
  21% {
    -moz-transform: rotate(18deg);
  }
  23% {
    -moz-transform: rotate(-16deg);
  }
  25% {
    -moz-transform: rotate(14deg);
  }
  27% {
    -moz-transform: rotate(-12deg);
  }
  29% {
    -moz-transform: rotate(10deg);
  }
  31% {
    -moz-transform: rotate(-8deg);
  }
  33% {
    -moz-transform: rotate(6deg);
  }
  35% {
    -moz-transform: rotate(-4deg);
  }
  37% {
    -moz-transform: rotate(2deg);
  }
  39% {
    -moz-transform: rotate(-1deg);
  }
  41% {
    -moz-transform: rotate(1deg);
  }
  43% {
    -moz-transform: rotate(0);
  }
  100% {
    -moz-transform: rotate(0);
  }
}

@keyframes ring {
  0% {
    transform: rotate(0);
  }
  1% {
    transform: rotate(30deg);
  }
  3% {
    transform: rotate(-28deg);
  }
  5% {
    transform: rotate(34deg);
  }
  7% {
    transform: rotate(-32deg);
  }
  9% {
    transform: rotate(30deg);
  }
  11% {
    transform: rotate(-28deg);
  }
  13% {
    transform: rotate(26deg);
  }
  15% {
    transform: rotate(-24deg);
  }
  17% {
    transform: rotate(22deg);
  }
  19% {
    transform: rotate(-20deg);
  }
  21% {
    transform: rotate(18deg);
  }
  23% {
    transform: rotate(-16deg);
  }
  25% {
    transform: rotate(14deg);
  }
  27% {
    transform: rotate(-12deg);
  }
  29% {
    transform: rotate(10deg);
  }
  31% {
    transform: rotate(-8deg);
  }
  33% {
    transform: rotate(6deg);
  }
  35% {
    transform: rotate(-4deg);
  }
  37% {
    transform: rotate(2deg);
  }
  39% {
    transform: rotate(-1deg);
  }
  41% {
    transform: rotate(1deg);
  }
  43% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 1.5s;
}

.slide-fade-enter {
  transform: translateY(-100%);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateY(100%);
  opacity: 0;
}

.message-box + .message-box {
  position: absolute;
  width: 100%;
}

.transition-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
}
</style>
